import { Link, graphql } from 'gatsby';
import React from 'react';
import Img from 'gatsby-image';

import MarbleHillHouse from '../assets/Images/Projects/marblehouse/newmarblehillhouse.jpg';
import PortlandPlace from '../assets/Images/Projects/portlandplace/devonshiresquare.jpg';
import Wimpole from '../assets/Images/Projects/wimpole.jpg';
import { Col, Row } from 'react-bootstrap';

const PLANT_ROOM_EXAMPLES = [
  {
    title: 'Marble House - London',
    desc: `A dated boiler room installed in the 1970’s by the old GLC.
                  Parts were obsolete for the ageing boilers and plant. The
                  plant room was in dire need of modernisation.`,
    link: '/commercial-plant-room-installation-marble-house/',
    image: MarbleHillHouse,
  },
  {
    title: 'Portland Place - London',
    desc: `A boiler room which has had several updates and adaptions over the years. After the work was completed
    the new plant room now comprises of 3 new A rated condensing gas boilers with a new flue system discharging high above the roof of the building. `,
    link: '/commercial-plant-room-installation-portland-place/',
    image: PortlandPlace,
  },
  {
    title: 'Wimpole Street - London',
    desc: `The old boiler room occupied two rooms and was deigned and built in
            the 1980’s. The plant was removed and a complete new boiler made in
            half the original space. This allowed the other half to be turned in
            to a reception area and washroom facilities.`,
    link: '/commercial-plant-room-installation-portland-place/',
    image: Wimpole,
  },
];

export default () => {
  return (
    <>
      <section className="my-4">
      <p>Take a look at some of our previous plant room designs and installations:</p>
        <ul className="list-unstyled">
          {PLANT_ROOM_EXAMPLES.map((i) => (
            <li className='my-4' key={i.link}>
              <Row>
                <Col className='col-12 col-sm-6'>
                  <Link to={i.link} className="">
                    <h4>{i.title}</h4>
                  </Link>
                  <p>{i.desc}</p>
                </Col>
                <Col className='col-12 col-sm-6'>
                  <img
                    src={i.image}
                    className='w-100'
                    alt="React Bootstrap logo"
                  />
                </Col>
              </Row>
            </li>
          ))}
        </ul>
      </section>
    </>
  );
};

export const query = graphql`
  query PlantRoomExamplesImages {
    marblehouse: file(
      relativePath: { eq: "Projects/marblehouse/marblehouse.jpg" }
    ) {
      childImageSharp {
        # Specify the image processing specifications right in the query.
        fluid(maxWidth: 945) {
          ...GatsbyImageSharpFluid
          ...GatsbyImageSharpFluidLimitPresentationSize
        }
      }
    }
    newmarblehillhouse50: file(
      relativePath: { eq: "Projects/marblehouse/newmarblehillhouse.jpg" }
    ) {
      childImageSharp {
        # Specify the image processing specifications right in the query.
        fluid(maxWidth: 945) {
          ...GatsbyImageSharpFluid
          ...GatsbyImageSharpFluidLimitPresentationSize
        }
      }
    }
    marblehouse1: file(
      relativePath: { eq: "Projects/marblehouse/marblehouse1.jpg" }
    ) {
      childImageSharp {
        # Specify the image processing specifications right in the query.
        fluid(maxWidth: 945) {
          ...GatsbyImageSharpFluid
          ...GatsbyImageSharpFluidLimitPresentationSize
        }
      }
    }
    marblehouse2: file(
      relativePath: { eq: "Projects/marblehouse/marblehouse2.jpg" }
    ) {
      childImageSharp {
        # Specify the image processing specifications right in the query.
        fluid(maxWidth: 945) {
          ...GatsbyImageSharpFluid
          ...GatsbyImageSharpFluidLimitPresentationSize
        }
      }
    }
    marblehouse3: file(
      relativePath: { eq: "Projects/marblehouse/marblehouse3.jpg" }
    ) {
      childImageSharp {
        # Specify the image processing specifications right in the query.
        fluid(maxWidth: 945) {
          ...GatsbyImageSharpFluid
          ...GatsbyImageSharpFluidLimitPresentationSize
        }
      }
    }
    marblehouse4: file(
      relativePath: { eq: "Projects/marblehouse/marblehouse4.jpg" }
    ) {
      childImageSharp {
        # Specify the image processing specifications right in the query.
        fluid(maxWidth: 945) {
          ...GatsbyImageSharpFluid
          ...GatsbyImageSharpFluidLimitPresentationSize
        }
      }
    }
    marblehouse5: file(
      relativePath: { eq: "Projects/marblehouse/marblehouse5.jpg" }
    ) {
      childImageSharp {
        # Specify the image processing specifications right in the query.
        fluid(maxWidth: 945) {
          ...GatsbyImageSharpFluid
          ...GatsbyImageSharpFluidLimitPresentationSize
        }
      }
    }
    marblehouse6: file(
      relativePath: { eq: "Projects/marblehouse/marblehouse6.jpg" }
    ) {
      childImageSharp {
        # Specify the image processing specifications right in the query.
        fluid(maxWidth: 945) {
          ...GatsbyImageSharpFluid
          ...GatsbyImageSharpFluidLimitPresentationSize
        }
      }
    }
  }
`;
